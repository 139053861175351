<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-can="'create_peoples'"
        color="primary"
        dark
        :to="{name: 'citizens.create'}"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        {{ $('create') }}
      </v-btn>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#098064"
            small
            class="mx-2"
            v-bind="attrs"
            style="color: white"
            v-on="on"
            @click.prevent="export_all()"
          >
            تصدير
            <v-icon
              right
            >
              mdi-file-excel
            </v-icon>
          </v-btn>
        </template>
        <span>  تصدير  الاشخاص والمركبات  </span>
      </v-tooltip>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="citizens"
      :options.sync="options"
      :server-items-length="citizensTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText')
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>

      <template v-slot:item.created_at="{item}">
        {{ item.created_at | format_date }}
      </template>
      <template v-slot:item.enter_all_cars="{ item }">
        <v-chip
          :color="item.enter_all_cars ? 'success' : 'error'"
          label
          outlined
          small
        >
          {{ item.enter_all_cars ? 'مسموح' : 'غير مسموح' }}
        </v-chip>
      </template>

      <template v-slot:item.expire_date="{ item }">
        <v-chip
          v-if="item.expire_date"
          :color="Date.now() > new Date(item.expire_date) ? 'error': ''"
          label
          small
        >
          {{ item.expire_date }}
        </v-chip>
      </template>

      <template v-slot:item.active="{ item }">
        <v-btn
          v-can="'update_peoples'"
          depressed
          :color="item.active ? 'success' : 'error'"
          rounded
          small
          outlined
          @click="toggleActive(item)"
        >
          {{ item.active ? 'مفعل' : 'معطل' }}
          <v-icon
            dark
            right
          >
            {{ item.active ? 'mdi-check' : 'mdi-block-helper' }}
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.identifier_name="{ item }">
        <v-chip
          v-if="item.identifier"
          label
          color="black"
          small
          dark
        >
          {{ item.identifier.name }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <menu-item v-can="'read_peoples'" :label="$('show_details')" color="info" icon="mdi-eye" @click="dialogModal(true, 'details', item.id)"></menu-item>
              <menu-item v-can="'read_cars'" :label="$('show_car')" color="accent" icon="mdi-car" @click="showCars(item.id)"></menu-item>
              <menu-item v-can="'update_peoples'" :label="$('update')" color="info" icon="mdi-pencil" @click="editFunction(item.id)"></menu-item>
              <menu-item v-can="'delete_peoples'" :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item.id)"></menu-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <people-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></people-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import PeopleDialog from '@/views/pages/peoples/dialog.vue'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'
import toast from '@/store/toast'
import router from '@/router'

import MenuItem from '@/components/menu/menuItem.vue';

export default {
  name: 'Citizen',
  components: { DatatableHeader, PeopleDialog, MenuItem },
  data: () => ({
    translationKey: 'pages.citizens',
    filters: false,
    citizens: [],
    citizen: 1,
    citizensTotal: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'details',
      id: null,
    },
    headers: [
      {
        text: 'number',
        align: 'center',
        sortable: false,
        value: 'identification_number',
        sort: true,
        filter: {
          menu: false,
          type: 'number',
        },
      },
      {
        text: 'name',
        value: 'name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'page',
        value: 'page',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'sequence',
        value: 'sequence',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'identifier',
        value: 'identifier_name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'active',
        value: 'active',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'switch',
          label: 'مفعل',
        },
      },

      {
        text: 'expire_date',
        value: 'expire_date',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
      {
        text: 'created_at',
        value: 'created_at',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  setup() {
    return {

    }
  },
  mounted() {
    this.options = getOptions(this.$route, this.options);

    this.fetch();
  },

  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        citizen: this.citizen,
        filters,
      })
      const response = await axios.get(`/peoples/dt?${query}`);
      this.citizens = response.data.data.data
      this.citizensTotal = response.data.data.total
      this.loading = false
    },
    async toggleActive(item) {
      if (!this.$can('update_peoples')) {
        return
      }
      item.active = !item.active;
      await axios.post(`/peoples/edit/${item.id}`, item);
      await toast.dispatch('success', this.$t('form.success.change'))
      await this.fetch();
    },
    async editFunction($id) {
      await router.push(`/people/${$id}`);
    },
    async showCars(id) {
      await router.push(`/cars/index/${id}`);
    },
    async export_all() {
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        filter: this.type,
        filters,
        citizen: this.citizen,
      });
      axios
        .get(`peoples/export?${query}`, {
          responseType: 'arraybuffer',
        })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        })
    },
    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    $(key) {
      return this.$t(`${this.translationKey}.${key}`);
    },
  },

}
</script>
