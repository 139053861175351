<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'info'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-info': isDetails}"
      >
        <span v-if="isDetails" class="">{{ $('details') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
      </v-card-title>
      <v-card-text>
        <h2 v-if="isDelete && people" class="my-3">
          {{ $('delete_msg', [people.name]) }}
        </h2>
        <div
          v-if="isDetails && people"
        >
          <v-row
            class="mt-2"
          >
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.id') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">{{ people.identification_number || people.id }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.name') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">{{ people.name }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.number') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">{{ people.number }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.page') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">{{ people.page }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.sequence') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">{{ people.sequence }}</span>
            </v-col>
          </v-row>
          <v-row v-if="people.citizen === 0">
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.enter_all_cars') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">{{ people.enter_all_cars ? "مسموح" : "غير مسموح" }}</span>
            </v-col>
          </v-row>
          <v-row v-if="people.enter_all_cars === 1">
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.cars_count') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">{{ people.cars_count }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.identifier') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">{{ people.identifier.name }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.identifier_type') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">{{ people.identifier_type }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.active') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">{{ people.active ? "فعال" : "غير فعال" }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <label>{{ $t('pages.peoples.details.checkpoints') }}</label>
            </v-col>
            <v-col cols="8">
              <span class="font-weight-bold">
                <v-chip
                  v-for="checkpoint in people.checkpoints"
                  :key="checkpoint"
                  class="ma-2"
                  label
                  color="info"
                  outlined
                >
                  {{ checkpoint.name }}
                </v-chip>

              </span>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          :loading="submitLoading"
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'PeopleDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'details',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  setup() {
    return {
    }
  },
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    people: null,

  }),
  computed: {
    isDetails() {
      return this.data.type === 'details'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.id) {
            const people = await axios.get(`/peoples/${val.id}`);
            this.people = people.data.data
          }
        } catch (error) {
          //  //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        if (this.isDelete) {
          await axios.delete(`/peoples/delete/${this.data.id}`);
          await toast.dispatch('success', this.$t('form.success.delete'))
        }
        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //  //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
